<template>
  <div>
    <v-speed-dial
      v-model="fab"
      fixed
      bottom
      right
      open-on-hover
      transition='slide-y-reverse-transition'
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="blue darken-2"
          dark
          fab
        >
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-help</v-icon>
        </v-btn>
      </template>
      <div>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
          v-on="on"
          href="https://confluence.app.medcity.net/c/display/EM/Evolve+Marketplace"
          target="_blank"
          fab
          dark
          small
          color="indigo"
          >
            <v-icon>mdi-text-box-search</v-icon>
          </v-btn>
        </template>
        <span>View Docs</span>
      </v-tooltip>
      </div>
    </v-speed-dial>
  </div>
</template>

<script>
export default {
  name: 'help-component',
  data: () => ({
    fab: false
  })
}
</script>
