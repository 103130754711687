export default {
  state: {
    guid: 'test'
  },
  getters: {
    getGuid: state => {
      return state.guid
    }
  },
  mutations: {
    guidMut (state, payload) {
      state.guid = payload
    }
  },
  actions: {
    updateGuid (state, payload) {
      state.dispatch('guidMut', payload)
    }
  }
}
