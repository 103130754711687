import '@babel/polyfill'
import Vuex from 'vuex'
import Vue from 'vue'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import axios from 'axios'
import snackbarStore from '@/store/snackbar'
import dialogStore from '@/store/dialog'
import errorStore from '@/store/error'
import adminStore from '@/store/admin'
import editStore from '@/store/edit'
import guidStore from '@/store/guid'
import teams from '@/store/modules/teams'
import affiliates from '@/store/modules/affiliates'
import products from '@/store/modules/products'
// import moduleImport from '@/store/modules'

Vue.use(Vuex)

const modules = {
  // ...moduleImport,
  snackbarStore,
  dialogStore,
  errorStore,
  adminStore,
  editStore,
  guidStore,
  teams,
  affiliates,
  products
}

export default new Vuex.Store({
  state: {
    breadcrumbs: [ ]
  },
  getters: {
    breadcrumbs: state => {
      return state.breadcrumbs
    }
  },
  mutations: {
    breadcrumbs (state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
    },
    addBreadcrumbs (state, breadcrumbs) {
      state.breadcrumbs = state.breadcrumbs.concat(breadcrumbs)
    }
  },
  modules: {
    ...modules,
    oidcStore: vuexOidcCreateStoreModule(
      {
        authority: process.env.VUE_APP_AUTHORITY,
        clientId: process.env.VUE_APP_CLIENTID,
        redirectUri: process.env.VUE_APP_REDIRECTURI,
        silentRedirectUri: process.env.VUE_APP_SILENTREDIRECTURI,
        responseType: 'code',
        scope: 'openid profile',
        loadUserInfo: false,
        automaticSilentRenew: true
      },
      // Optional OIDC store settings
      {
        dispatchEventsOnWindow: true
      },
      // Optional OIDC event listeners
      {
        userLoaded: (user) => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`
        }
      }
    )
  }
})
