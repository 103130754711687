export default {
  state: {
    errorMessage: '',
    technicalDetails: '',
    displayError: false,
    persistErrorDialog: false,
    retryMethod: () => { return false }
  },
  mutations: {
    hideErrorDialog (state) {
      state.displayError = false
    },
    showErrorDialog (state, { errorData, persistErrorDialog, retryMethod }) {
      state.displayError = true
      state.persistErrorDialog = persistErrorDialog
      if (errorData.response.status === 403) {
        state.errorMessage = errorData.response.data ? errorData.response.data.errorMessage : 'You do not have access to this page'
      } else {
        state.errorMessage = errorData.response.data ? errorData.response.data.errorMessage : 'No error details are available'
        state.technicalDetails = errorData.response.data ? errorData.response.data.technicalDetails : null
      }
      state.retryMethod = retryMethod
    },
    triggerErrorRetry (state) {
      state.retryMethod()
      state.displayError = false
    }
  }
}
