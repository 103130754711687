export default {
  state: {
    isEdittingDepartment: false,
    isEdittingCollege: false
  },
  getters: {
    isEdittingDepartment: state => {
      return state.isEdittingDepartment
    },
    isEdittingCollege: state => {
      return state.isEdittingCollege
    }
  },
  mutations: {
    editDepartment (state, value) {
      state.isEdittingDepartment = value
    },
    editCollege (state, value) {
      state.isEdittingCollege = value
    }
  }
}
