import axios from 'axios'

export default {
  namespaced: true,
  state: {
    affiliates: [ ],
    isLoadingAffiliates: false
  },
  mutations: {
    setAffiliates: function (state, payload) {
      state.affiliates = payload
    },
    setIsLoadingAffiliates: function (state, isLoadingAffiliates) {
      state.isLoadingAffiliates = isLoadingAffiliates
    }
  },
  actions: {
    resetAffiliates: function ({ commit }) {
      commit('setAffiliates', [ ])
    },
    getAffiliates: function ({ commit, dispatch }) {
      commit('setIsLoadingAffiliates', true)
      axios(
        `${process.env.VUE_APP_BASEURL}/affiliates/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setIsLoadingAffiliates', false)
            commit('setAffiliates', response.data)
          },
          (error) => {
            commit('setIsLoadingAffiliates', false)
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: false,
              retryMethod: () => dispatch('getAffiliates')
            }, {
              root: true
            })

            return error
          })
    }
  },
  getters: {
    affiliates: function (state) {
      return state.affiliates
    },
    isLoadingAffiliates: function (state) {
      return state.isLoadingAffiliates
    }
  }
}
