<template>
  <v-app v-bind:style="adminAppSpace" v-bind:class="{ adminAppSpace: adminSettings && adminSettings.showBannerMessage }">
    <v-alert ref="banner" id="banner" v-if="adminSettings && adminSettings.showBannerMessage" type="info" class="admin-alert" tile>
      <span v-html="adminSettings.bannerMessage"></span>
    </v-alert>

    <v-app-bar v-bind:style="adminBarSpace" flat app clipped-left color="#03173E" dark class="top-bar" v-bind:class="{ adminBarSpace: adminSettings && adminSettings.showBannerMessage }">
      <v-toolbar-title><router-link class="nostyle" to="/">Evolve Marketplace</router-link></v-toolbar-title>

      <div class="pl-4">
        <v-btn text dense depressed small v-for="item in links" :key="item.title" router v-bind:to="item.link">
          {{ item.title }}
        </v-btn>
      </div>

      <v-spacer />

      <span v-if="oidcStore && oidcStore.user">{{ oidcStore.user.firstName}} {{ oidcStore.user.lastName }}</span>

      <v-menu left bottom :close-on-content-click="false" nudge-top="-52" nudge-right="-5">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon class='pa-3' v-if="!oidcStore || thumbnail == ''">mdi-account-circle</v-icon>
            <v-avatar v-if="oidcStore && thumbnail != ''" size="25">
              <img :src="thumbnail" :alt="oidcStore.user.firstName + ' ' + oidcStore.user.lastName">
            </v-avatar>
          </v-btn>
        </template>

        <v-card style="min-width: 250px;">
          <v-list-item-content class="justify-center">
            <v-container class="mx-auto text-center">
              <v-avatar color="indigo">
                <v-icon dark v-if="!oidcStore || thumbnail == ''">mdi-account-circle</v-icon>
                <img v-if="oidcStore && thumbnail != ''" :src="thumbnail" :alt="oidcStore.user.firstName + ' ' + oidcStore.user.lastName">
              </v-avatar>

              <div style="height: 5px"></div>

              <h3 v-if="oidcStore && oidcStore.user">{{ oidcStore.user.firstName}} {{ oidcStore.user.lastName }}</h3>

              <v-divider class="my-3" />

              <a v-if="adminSettings.enableBadges || adminSettings.isAdmin" :href="'/badges/user/' + oidcStore.user.sub">View My Badges</a>

              <v-switch style="margin-left: 20px;" v-model="darkMode" label="Toggle Dark Mode"></v-switch>
            </v-container>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-toolbar class="breadcrumb-toolbar" flat short>
      <v-btn id="no-background-hover" icon to="/" :ripple="false"><v-icon>mdi-home</v-icon></v-btn>
      <v-breadcrumbs :items="breadcrumbs">
      </v-breadcrumbs>
    </v-toolbar>

    <v-divider />

    <!-- If using vue-router -->
    <v-main :style="$vuetify.theme.dark ? '' : 'background-color: #f5f5f5 !important'" :class="this.oidcIsAuthenticated === false ? 'd-flex align-center justify-center' : undefined">
      <v-container fluid class="scroll-helper">

        <!-- Sizes your content based upon application components -->
        <!-- Provides the application the proper gutter -->
        <transition name="fade-transition" mode="out-in" v-show="oidcStore && oidcStore.user && oidcStore.user.firstName">
          <router-view></router-view>
        </transition>

        <div class="d-flex flex-column align-center justify-center my-auto" v-if="this.oidcIsAuthenticated === false">
          <v-progress-circular :size="125" indeterminate color="primary"></v-progress-circular>
          <h1 class="mt-5">Authenticating User</h1>
          <p>This may take a moment and the page may refresh during this time.</p>
          <v-btn color="primary" elevation="1" @click="reloadPage()">Reload</v-btn>
        </div>

      </v-container>
    </v-main>

    <v-snackbar v-model="snackbarStore.displaySnack" top :color="snackbarStore.snackColor">
      {{ snackbarStore.snackMessage }}
      <v-btn dark text @click="hideQuickNotify" icon><v-icon>mdi-close</v-icon></v-btn>
    </v-snackbar>

    <v-dialog v-model="displayDialog" width="500">
      <v-card>
        <v-card-title class="headline" :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'" primary-title>Confirmation Required</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" v-html="dialogStore.message"></v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$store.commit('triggerCancel')">Cancel</v-btn>
          <v-btn color="primary" text @click="$store.commit('triggerConfirm')">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="displayErrorDialog" width="500" :persistent="errorStore.persistErrorDialog">
      <v-card>
        <v-card-title :class="$vuetify.theme.dark ? 'headline grey darken-3' : 'headline grey lighten-3'" primary-title>Something went wrong!</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-img src="@/assets/oops.png" max-width="150" text-md-center/>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <p class="text-md-center" style="font-size: 16px" v-html="errorStore.errorMessage"></p>
              </v-col>
            </v-row>

            <v-row v-if="errorStore.technicalDetails">
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Technical Details</v-expansion-panel-header>
                    <v-expansion-panel-content>{{ errorStore.technicalDetails }}</v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$store.commit('triggerErrorRetry')">Retry</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <help-component />

  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
import helpComponent from '@/components/helpComponent'

export default {
  name: 'App',
  components: {
    'help-component': helpComponent
  },

  watch: {
    oidcUser: function () {
      this.$store.dispatch('getAdminSettings')
      this.getThumbnailPhoto()
    },

    darkMode: function (newValue) {
      this.$vuetify.theme.dark = newValue
      if (newValue) {
        localStorage.setItem('darkMode', newValue)
      } else {
        localStorage.removeItem('darkMode')
      }
    }
  },

  computed: {
    ...mapState(['snackbarStore', 'dialogStore', 'errorStore', 'oidcStore']),
    ...mapGetters(['adminSettings', 'oidcUser', 'oidcIsAuthenticated', 'breadcrumbs']),

    adminBarSpace: {
      get () {
        return {
          top: `${this.bannerSize}px !important`
        }
      }
    },

    adminAppSpace: {
      get () {
        return {
          paddingTop: `${this.bannerSize}px !important`
        }
      }
    },

    displaySnack: {
      get () { return this.snackbarStore.displaySnack },
      set (value) { this.$store.commit('updateDisplaySnack', value) }
    },

    displayDialog: {
      get () { return this.dialogStore.displayDialog },
      set (value) { this.$store.commit('triggerCancel') }
    },

    displayErrorDialog: {
      get () { return this.errorStore.displayError },
      set (value) { this.$store.commit('hideErrorDialog') }
    },

    links: function () {
      if (this.adminSettings && this.adminSettings.showAdminButton) {
        return this.items
      } else {
        return this.items.filter(x => {
          return !x.requireAdmin
        })
      }
    }
  },

  data: () => ({
    darkMode: false,
    bannerTimeout: null,
    bannerSize: 0,
    drawer: true,
    thumbnail: '',
    isLoading: false,
    showLoadingScreen: true,
    items: [
      { title: 'My Products', icon: 'mdi-application', link: '/products', requireAdmin: false },
      { title: 'University', icon: 'mdi-application', link: '/colleges', requireAdmin: true },
      { title: 'My Teams', icon: 'mdi-application', link: '/teams', requireAdmin: false },
      { title: 'Learning Center', icon: 'mdi-school', link: '/learning-center', requireAdmin: false },
      { title: 'Admin', icon: 'mdi-star', link: '/admin/settings/flags', requireAdmin: true }
    ]
  }),

  methods: {
    reloadPage: function () {
      this.isLoading = true

      setTimeout(() => {
        this.isLoading = false
      }, 10000)
      location.reload()
    },

    displayLoadingScreen: function () {
      let display = true

      console.log(this.oidcStore.user)
      if (this.oidcStore && this.oidcStore.user && this.oidcStore.user !== null) {
        display = false
      }

      this.showLoadingScreen = display
    },

    setDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },

    hideQuickNotify: function () {
      this.$store.commit('hideSnack')
    },

    getBannerSize: function () {
      if (document.getElementById('banner')) {
        this.bannerSize = document.getElementById('banner').clientHeight
      }

      this.bannerTimeout = setTimeout(this.getBannerSize, 500)
    },

    getThumbnailPhoto: function () {
      axios(`${process.env.VUE_APP_BASEURL}/user/thumbnail`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        if (response.data !== '') {
          this.thumbnail = `data:image/png;base64,${response.data}`
        }
      }, () => {
        this.thumbnail = ''
      })
    }
  },

  mounted: function () {
    this.getBannerSize()

    let storageValue = localStorage.getItem('darkMode')
    if (storageValue) {
      this.darkMode = true
      this.$vuetify.theme.dark = true
    }
  },

  destroyed: function () {
    clearTimeout(this.bannerTimeout)
  }
}
</script>

<style scoped>
a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.v-app-bar__nav-icon {
  margin-left: 0 !important;
}

.error-message {
  font-size: 15px;
}

.v-toolbar {
  max-height: 60px !important;
}

.v-main {
  padding-top: 0px !important;
}

.admin-alert {
  position: fixed;
  z-index: 500;
  left: 0;
  right: 0;
  top: 0;
}

.adminBarSpace {
  top:56px !important;
}

.adminAppSpace {
  padding-top:56px !important;
}

#no-background-hover::before {
  background-color: transparent !important;
}

.breadcrumb-toolbar {
  margin-top:65px !important;
}

</style>
