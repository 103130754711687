import VueRouter from 'vue-router'
import store from './store/index'
import notFound from './pages/notFound'
import { vuexOidcCreateRouterMiddleware, vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

const routes = [
  { path: '/', component: () => import(/* webpackChunkName: "home" */ '@/pages/home/index') },
  { path: '/token', component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/token') },
  { path: '/callback', component: () => import(/* webpackChunkName: "home" */ '@/pages/authentication/callback') },
  { path: '/products', component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productList/productList') },
  {
    path: '/products/:id',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productEdit/productEdit'),
    children: [
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productEdit/components/productSettings')
      },
      {
        path: 'keys',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productEdit/components/productKeys')
      },
      {
        path: 'apis',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productEdit/components/productApis')
      },
      {
        path: 'subscriptions',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productEdit/components/productSubscriptions')
      },
      {
        path: 'cloudAccounts',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productEdit/components/productCloudAccounts')
      }
    ]
  },
  { path: '/product-view/:id', component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productDetails/productDetails') },
  { path: '/products/:id/builder', component: () => import(/* webpackChunkName: "apiList" */ '@/pages/apis/apiBuilder/apiBuilder') },
  { path: '/apis/:id', component: () => import(/* webpackChunkName: "apiList" */ '@/pages/apis/apiEdit/apiEdit') },
  { path: '/apis/', component: () => import(/* webpackChunkName: "apiList" */ '@/pages/apis/apiList/apiList') },
  { path: '/apis/details/:id', component: () => import(/* webpackChunkName: "apiDetails" */ '@/pages/apis/apiDetails/apiDetails') },
  { path: '/api-view/:id', component: () => import(/* webpackChunkName: "apiDetails" */ '@/pages/apis/apiDetails/apiDetails') },
  { path: '/product-builder', component: () => import(/* webpackChunkName: "home" */ '@/pages/products/productBuilder/productBuilder') },
  { path: '/team-builder', component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamBuilder/teamBuilder') },
  { path: '/teams', component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamList/teamList') },
  { path: '/packages', component: () => import(/* webpackChunkName: "packageList" */ '@/pages/packages/packageList/packageList') },
  {
    path: '/teams/:id',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamEdit/teamEdit'),
    children: [
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamEdit/components/teamSettings')
      },
      {
        path: 'team-cloud-account',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamEdit/components/teamCloudAccount')
      },
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamEdit/components/teamProducts')
      },
      {
        path: 'repositories',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamEdit/components/teamRepositories')
      },
      {
        path: 'templates',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamEdit/components/teamTemplates')
      },
      {
        path: 'github-organizations',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/teams/teamEdit/components/teamGithubOrganizations')
      }
    ]
  },
  { path: '/teams/:id/repositories/builder', component: () => import(/* webpackChunkName: "home" */ '@/pages/repositories/repositoryBuilder/repositoryBuilder') },
  { path: '/teams/:id/repositories/:repoId', component: () => import(/* webpackChunkName: "home" */ '@/pages/repositories/repositoryEdit/repositoryEdit') },
  { path: '/pulse/:surveyId', component: () => import(/* webpackChunkName: "home" */ '@/pages/pulseBot/surveyQuestion') },
  { path: '/products/:id/cloud/builder', component: () => import(/* webpackChunkName: "home" */ '@/pages/cloud/cloudBuilder/cloudBuilder') },
  { path: '/products/:id/cloud/subscriptions/:subscriptionId', component: () => import(/* webpackChunkName: "home" */ '@/pages/cloud/subscriptions/subscriptionOverview') },
  {
    path: '/products/:id/cloud/accounts/:cloudAccountId',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/cloud/cloudAccount/cloudEdit'),
    children: [
      {
        path: 'deployments',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/cloud/cloudAccount/components/cloudInstallation')
      }
    ]
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/admin'),
    beforeEnter: (to, from, next) => {
      getAdminDetails(to, from, next)
    },
    children: [
      {
        path: 'settings/flags',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/settings/flags/flags'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'settings/actions',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/settings/actions/actions'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'users/university',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/users/university/users'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'api/users',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/users/devServices/users'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'badges/users',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/users/badgeUsers'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'api/deployments',
        meta: {
          group: 'api'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/apis/deployments/deployments'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'api/affiliates',
        meta: {
          group: 'api'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/apis/affiliates/affiliates'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'api/policies',
        meta: {
          group: 'api'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/apis/policies/policies'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'repos/public',
        meta: {
          group: 'repos'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/repos/public/public'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },
      {
        path: 'repos/promotions',
        meta: {
          group: 'repos'
        },
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/repos/promotions/promotions'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      },

      {
        path: 'pulseBot',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/admin/pulseBot'),
        beforeEnter: (to, from, next) => {
          getAdminDetails(to, from, next)
        }
      }
    ]
  },
  { path: '/learning-center', component: () => import(/* webpackChunkName: "home" */ '@/pages/learningCenter/learningCenter') },
  { path: '/github-app/:teamId', component: () => import(/* webpackChunkName: "home" */ '@/pages/github/newApp/newApp') },
  { path: '/github-app/', component: () => import(/* webpackChunkName: "home" */ '@/pages/github/newApp/newApp') },
  { path: '/templates/:templateId/releases/:releaseId', component: () => import(/* webpackChunkName: "home" */ '@/pages/templates/editTemplate/editTemplate') },
  { path: '/templates/:templateId', component: () => import(/* webpackChunkName: "home" */ '@/pages/templates/editTemplate/editTemplate') },
  { path: '/templates', component: () => import(/* webpackChunkName: "home" */ '@/pages/templates/listTemplates/listTemplates') },
  { path: '/templates/:templateId/installs/:installationId', component: () => import(/* webpackChunkName: "home" */ '@/pages/templates/templateInstalls/templateInstalls') },
  { path: '/colleges',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/university/colleges/listColleges/listColleges'),
    beforeEnter: (to, from, next) => {
      getUniversityUser(to, from, next)
    }
  },
  {
    path: '/colleges/:id/departments',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/university/departments/listDepartments/listDepartments'),
    beforeEnter: (to, from, next) => {
      getUniversityUser(to, from, next)
    },
    children: [
      {
        path: ':departmentId/courses',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/university/courses/listCourses/listCourses'),
        beforeEnter: (to, from, next) => {
          getUniversityUser(to, from, next)
        }
      }
    ]
  },
  {
    path: '/badges/user/:username',
    component: () => import(/* webpackChunkName: "GroupList" */ '@/pages/badges/badges/badgeList/employeeBadgeList'),
    beforeEnter: (to, from, next) => {
      getBadgeUser(to, from, next)
    }
  },
  {
    path: '/badges/groups',
    component: () => import(/* webpackChunkName: "GroupList" */ '@/pages/badges/groups/groupList/groupList'),
    beforeEnter: (to, from, next) => {
      getBadgeUser(to, from, next)
    }
  },
  {
    path: '/badges/groups/:name',
    component: () => import(/* webpackChunkName: "GroupEdit" */ '@/pages/badges/groups/groupEdit/groupEdit'),
    beforeEnter: (to, from, next) => {
      getBadgeUser(to, from, next)
    }
  },
  {
    path: '/badges/details/:badgeName',
    component: () => import(/* webpackChunkName: "BadgeDetails" */ '@/pages/badges/badges/badgeDetails/badgeDetails'),
    beforeEnter: (to, from, next) => {
      getBadgeUser(to, from, next)
    }
  },
  {
    path: '/badges',
    component: () => import(/* webpackChunkName: "Badges" */ '@/pages/badges/badges/badgeList/badgeList'),
    beforeEnter: (to, from, next) => {
      getBadgeUser(to, from, next)
    }
  },
  {
    path: '/badges/assertions/:id',
    component: () => import(/* webpackChunkName: "AssertionDetails" */ '@/pages/badges/assertions/assertionDetails/assertionDetails'),
    beforeEnter: (to, from, next) => {
      getBadgeUser(to, from, next)
    }
  },
  {
    path: '/callback-silent',
    beforeEnter: (to, from, next) => {
      vuexOidcProcessSilentSignInCallback({
        authority: process.env.VUE_APP_AUTHORITY,
        clientId: process.env.VUE_APP_CLIENTID,
        redirectUri: process.env.VUE_APP_REDIRECTURI,
        silentRedirectUri: process.env.VUE_APP_SILENTREDIRECTURI,
        responseType: 'code',
        scope: 'openid profile',
        loadUserInfo: false,
        automaticSilentRenew: true
      })
      getAdminDetails(to, from, next)
      next('/')
    }
  },
  {
    path: '/404',
    component: notFound
  },
  {
    path: '*',
    redirect: '/404'
  }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: 'history',
  routes, // short for `routes: routes`,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

const getAdminDetails = function (to, from, next) {
  store.dispatch('getAdminSettings').then(() => {
    next()
  })
}

const getBadgeUser = function (to, from, next) {
  store.dispatch('getBadgeUser').then(() => {
    next()
  })
}

const getUniversityUser = function (to, from, next) {
  store.dispatch('getUniversityUser').then(() => {
    next()
  })
}

// generate a new GUID per page load
router.beforeEach(vuexOidcCreateRouterMiddleware(store))

router.beforeEach((to, from, next) => {
  var guid = uuidv4()
  store.commit('guidMut', guid)
  axios.defaults.headers.common['guid'] = store.getters.getGuid
  next()
})

export default router
