export default {
  state: {
    message: '',
    displayDialog: false,
    confirmMethod: () => { return false },
    cancelMethod: () => { return false }
  },
  mutations: {
    hideDialog (state) {
      state.displayDialog = false
    },
    showDialog (state, { message, confirmMethod, cancelMethod }) {
      state.displayDialog = true
      state.message = message
      state.confirmMethod = confirmMethod
      state.cancelMethod = cancelMethod
    },
    triggerCancel (state) {
      state.cancelMethod()
      state.displayDialog = false
    },
    triggerConfirm (state) {
      state.confirmMethod()
      state.displayDialog = false
    }
  }
}
