import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueRouter from 'vue-router'
import store from './store/index.js'
import VueClipboard from 'vue-clipboard2'
import UUID from 'vue-uuid'
import { ApplicationInsights, Telemetry } from '@microsoft/applicationinsights-web'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueClipboard)

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: process.env.VUE_APP_INSTRUMENTATIONKEY
} })
appInsights.loadAppInsights()
var telemetryInitializer = (envelope) => {
  var tags = envelope.tags
  tags['ai.user.id'] = store.state.oidcStore.user.sub
  envelope.data.guid = store.getters.getGuid
}
appInsights.addTelemetryInitializer(telemetryInitializer)
appInsights.trackPageView()
Vue.use(UUID)
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
