export default {
  state: {
    snackMessage: '',
    displaySnack: false,
    snackColor: 'success'
  },
  mutations: {
    triggerSnack (state, { snackMessage, snackColor }) {
      if (snackColor) {
        state.snackColor = snackColor
      } else {
        state.snackColor = 'success'
      }

      state.snackMessage = snackMessage
      state.displaySnack = true
    },
    hideSnack (state) {
      state.displaySnack = false
    },
    updateDisplaySnack (state, value) {
      state.displaySnack = value
    }
  }
}
