import axios from 'axios'

export default {
  state: {
    adminSettings: {
      isSuperUser: false,
      showAdminButton: false
    },
    badgeUser: null,
    universityUser: null
  },
  getters: {
    adminSettings: state => {
      return state.adminSettings
    },
    badgeUser: state => {
      return state.badgeUser
    },
    universityUser: state => {
      return state.universityUser
    }
  },
  mutations: {
    adminSettings (state, settings) {
      state.adminSettings = settings
    },
    badgeUser (state, user) {
      state.badgeUser = user
    },
    universityUser (state, user) {
      state.universityUser = user
    }
  },
  actions: {
    getAdminSettings ({ commit, dispatch }) {
      return axios.get(`${process.env.VUE_APP_BASEURL}/admin`)
        .then((response) => {
          commit('adminSettings', response.data)
        })
        .catch(function (error) {
          if (error) {
          }
        })
        .then(function () {
          // always executed
        })
    },
    getBadgeUser ({ state, dispatch, commit }) {
      if (!state.badgeUser) {
        return axios.get(`${process.env.VUE_APP_BADGEBASEAPIURL}/badgeusers`)
          .then((response) => {
            commit('badgeUser', response.data)
          })
          .catch(function (error) {
            commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => dispatch('getAdminSettings') })
          })
          .then(function () {
            // always executed
          })
      } else {
        return true
      }
    },
    getUniversityUser ({ state, dispatch, commit }) {
      if (!state.universityUser) {
        return axios.get(`${process.env.VUE_APP_UNIVERSITYBASEAPIURL}/api/universityusers/current`)
          .then((response) => {
            commit('universityUser', response.data)
          })
          .catch(function (error) {
            commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => dispatch('getUniversityUser') })
          })
          .then(function () {
            // always executed
          })
      } else {
        return true
      }
    },
    updateAdminSettings ({ commit }, obj) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/admin`,
        {
          method: 'PUT',
          data: JSON.stringify(obj.admin),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('adminSettings', response.data)
          })
        .catch(function (error) {
          if (error) { }
        })
    }
  }
}
