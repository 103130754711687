// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { mapGetters } from 'vuex'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false
  }
})
