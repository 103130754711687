import axios from 'axios'

export default {
  namespaced: true,
  state: {
    teamId: null,
    team: {
      displayName: '',
      description: '',
      members: [ ],
      affiliates: [ ]
    },
    teamProducts: [ ],
    teamTemplates: [ ],
    teamRepositories: [ ],
    teamOrganizations: [ ],
    isSavingTeam: false,
    isDeletingTeam: false,
    isLoadingProducts: false,
    isLoadingRepositories: false,
    isLoadingTemplates: false,
    isLoadingOrganizations: false,
    teamTemplateCount: 0,
    teamTemplateLimit: 10,
    teamTemplatePage: 1,
    teamTemplateSearch: '',
    teamTemplateOrderBy: 'name'
  },
  mutations: {
    setTeam: function (state, payload) {
      state.team = payload
    },
    setTeamId: function (state, teamId) {
      state.teamId = teamId
    },
    setIsDeletingTeam: function (state, isDeletingTeam) {
      state.isDeletingTeam = isDeletingTeam
    },
    setIsSavingTeam: function (state, isSavingTeam) {
      state.isSavingTeam = isSavingTeam
    },
    setTeamProducts: function (state, payload) {
      state.teamProducts = payload
    },
    setTeamRepositories: function (state, payload) {
      state.teamRepositories = payload
    },
    setTeamTemplates: function (state, payload) {
      state.teamTemplates = payload
    },
    setTeamOrganizations: function (state, payload) {
      state.teamOrganizations = payload
    },
    setIsLoadingProducts: function (state, isLoadingProducts) {
      state.isLoadingProducts = isLoadingProducts
    },
    setIsLoadingRepositories: function (state, isLoadingRepositories) {
      state.isLoadingRepositories = isLoadingRepositories
    },
    setIsLoadingTemplates: function (state, isLoadingTemplates) {
      state.isLoadingTemplates = isLoadingTemplates
    },
    setIsLoadingOrganizations: function (state, isLoadingOrganizations) {
      state.isLoadingOrganizations = isLoadingOrganizations
    },
    setTeamTemplateCount: function (state, count) {
      state.teamTemplateCount = count
    },
    setTeamTemplateLimit: function (state, limit) {
      state.teamTemplateLimit = limit
    },
    setTeamTemplatePage: function (state, page) {
      state.teamTeamplatePage = page
    },
    setTeamTemplateOrderBy: function (state, teamTemplateOrderBy) {
      state.teamTemplateOrderBy = teamTemplateOrderBy
    }
  },
  actions: {
    setTeamId: function ({ commit }, teamId) {
      commit('setTeamId', teamId)
    },
    getTeam: function ({ commit, state, dispatch }, callback) {
      return axios(
        `${process.env.VUE_APP_BASEURL}/teams/${state.teamId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setTeam', response.data)
            dispatch('getTeamProducts')
            dispatch('getTeamRepositories')
            dispatch('getTeamTemplates')
            dispatch('getTeamOrganizations')
            callback()
          },
          (error) => {
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: false,
              retryMethod: () => dispatch('getTeam', callback)
            }, {
              root: true
            })
          })
    },
    updateTeam: function ({ commit, dispatch, state }, team) {
      commit('setIsSavingTeam', true)
      axios(
        `${process.env.VUE_APP_BASEURL}/teams/${state.teamId}`,
        {
          method: 'PUT',
          data: JSON.stringify(team),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setIsSavingTeam', false)
            commit('setTeam', response.data)
            commit('triggerSnack', {
              snackMessage: 'The team has been updated successfully',
              snackColor: 'success'
            }, {
              root: true
            })
          },
          (error) => {
            commit('setIsSavingTeam', false)
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: false,
              retryMethod: () => dispatch('updateTeam', team)
            }, {
              root: true
            })

            return error
          })
    },
    deleteTeam: function ({ commit, dispatch, state }) {
      commit('setIsDeletingTeam', true)
      return axios(
        `${process.env.VUE_APP_BASEURL}/teams/${state.teamId}/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setIsDeletingTeam', false)
            dispatch('resetTeam')
            this.$router.push(`/teams/`)
          },
          (error) => {
            commit('setIsDeletingTeam', false)
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: false,
              retryMethod: () => dispatch('deleteTeam')
            }, {
              root: true
            })
          })
    },
    resetTeam: function ({ commit }) {
      commit('setTeam', {
        displayName: '',
        description: '',
        members: [ ],
        affiliates: [ ]
      })
      commit('setTeamProducts', [ ])
      commit('setTeamRepositories', [ ])
      commit('setTeamTemplates', [ ])
      commit('setTeamOrganizations', [ ])
    },
    getTeamProducts: function ({ commit, dispatch, state }) {
      commit('setIsLoadingProducts', true)
      return axios(
        `${process.env.VUE_APP_BASEURL}/teams/${state.teamId}/products`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setTeamProducts', response.data)
            commit('setIsLoadingProducts', false)
          },
          (error) => {
            commit('setIsLoadingProducts', false)
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: true,
              retryMethod: () => dispatch('getTeamProducts')
            }, {
              root: true
            })
          })
    },
    getTeamRepositories: function ({ commit, dispatch, state }) {
      commit('setIsLoadingRepositories', true)
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/teams/${state.teamId}/`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setIsLoadingRepositories', false)
            commit('setTeamRepositories', response.data)
          },
          (error) => {
            commit('setIsLoadingRepositories', false)
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: false,
              retryMethod: () => dispatch('getTeamRepositories')
            }, {
              root: true
            })
          })
    },
    getTeamTemplates: function ({ commit, dispatch, state }) {
      commit('setIsLoadingTemplates', true)
      axios(
        `${process.env.VUE_APP_TEMPLATEBASEURL}/api/teams/${state.teamId}/templates?page=${state.teamTemplatePage}&limit=${state.teamTemplateLimit}&search=${state.teamTemplateSearch}&orderBy=${state.teamTemplateOrderBy}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setTeamTemplates', response.data.items)
            commit('setTeamTemplateCount', Math.ceil(response.data.totalCount / state.teamTemplateLimit))
            commit('setIsLoadingTemplates', false)
          },
          (error) => {
            commit('setIsLoadingTemplates', false)
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: false,
              retryMethod: () => dispatch('getTeamTemplates')
            }, {
              root: true
            })
          })
    },
    getTeamOrganizations: function ({ commit, dispatch, state }) {
      commit('setIsLoadingOrganizations', true)
      return axios(
        `${process.env.VUE_APP_BASEURL}/github/teams/${state.teamId}/links`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setIsLoadingOrganizations', false)
            commit('setTeamOrganizations', response.data)
          },
          (error) => {
            commit('setIsLoadingOrganizations', false)
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: false,
              retryMethod: () => dispatch('getTeamOrganizations')
            }, {
              root: true
            })

            return error
          })
    },
    updateIdentity: function ({ commit, dispatch, state }, team) {
      axios(
        `${process.env.VUE_APP_BASEURL}/teams/identity`,
        {
          method: 'POST',
          data: JSON.stringify(team),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            commit('setTeam', response.data)
            commit('triggerSnack', {
              snackMessage: 'The team has been updated successfully',
              snackColor: 'success'
            }, {
              root: true
            })
          },
          (error) => {
            commit('showErrorDialog', {
              errorData: error,
              persistErrorDialog: false,
              retryMethod: () => dispatch('updateIdentity', team)
            }, {
              root: true
            })

            return error
          })
    }
  },
  getters: {
    team: function (state) {
      return state.team
    },
    teamId: function (state) {
      return state.teamId
    },
    isSavingTeam: function (state) {
      return state.isSavingTeam
    },
    isDeletingTeam: function (state) {
      return state.isDeletingTeam
    },
    teamProducts: function (state) {
      return state.teamProducts
    },
    teamRepositories: function (state) {
      return state.teamRepositories
    },
    teamOrganizations: function (state) {
      return state.teamOrganizations
    },
    isLoadingProducts: function (state) {
      return state.isLoadingProducts
    },
    isLoadingRepositories: function (state) {
      return state.isLoadingRepositories
    },
    isLoadingTemplates: function (state) {
      return state.isLoadingTemplates
    },
    teamTemplatePage: function (state) {
      return state.teamTemplatePage
    },
    teamTemplateCount: function (state) {
      return state.teamTemplateCount
    },
    teamTemplates: function (state) {
      return state.teamTemplates
    }
  }
}
