import axios from 'axios'

export default {
  namespaced: true,
  state: {
    teamId: null,
    currentProduct: null,
    isSavingProduct: false,
    isDeletingProduct: false
  },
  mutations: {
    setCurrentProduct: function (state, payload) {
      state.currentProduct = payload
    },
    setTeamId: function (state, teamId) {
      state.teamId = teamId
    },
    setIsDeletingProduct: function (state, isDeletingProduct) {
      state.isDeletingProduct = isDeletingProduct
    },
    setIsSavingProduct: function (state, isSavingProduct) {
      state.isSavingProduct = isSavingProduct
    }
  },
  actions: {
    setTeamId: function ({ commit }, teamId) {
      commit('setTeamId', teamId)
    }
  },
  getters: {
    currentProduct: function (state) {
      return state.currentProduct
    },
    teamId: function (state) {
      return state.teamId
    },
    isSavingProduct: function (state) {
      return state.isSavingProduct
    },
    isDeletingProduct: function (state) {
      return state.isDeletingProduct
    }
  }
}
